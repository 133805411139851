<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="searchFilter"
                debounce="1000"
                type="search"
                placeholder="Type to Search"
                @input="onStartFilter"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="light" :disabled="!searchFilter" @click="searchFilter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-show="!busyFlag">
      <b-row>
        <b-col>
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table selectable select-mode="single" primary-key="name" ref="searchOnTable" :per-page="perPage" :current-page="currentPage" :items="ports" :fields="visible_fields" :filter="searchFilter" @row-selected="onRowSelected"
                   @filtered="onFiltered" thead-class="d-none">
          </b-table>
        </b-col>
      </b-row>
    </div>
    <div align="center" class="text-sm-centercenter text-danger my-2" v-show="busyFlag">
      <b-spinner small class="align-middle"></b-spinner>
      <strong>&nbsp;Searching...</strong>
    </div>
  </b-container>
</template>

<script>
import {eventBus} from "@/main";

let ports = require("@/data/ports.json")

export default {
  name: "Search",
  data() {
    return {
      ports: ports,
      busyFlag: false,
      searchFilter: null,
      filterOn: ['name'],
      totalRows: 1,
      currentPage: 1,
      perPage: 12,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
    }
  },
  mounted() {
    this.totalRows = this.ports.length
  },
  methods: {
    onStartFilter() {
      this.busyFlag = true
    },
    onRowSelected(items) {
      let lat = items[0].lat
      let lon = items[0].lon
      eventBus.$emit('show-from-search', {lat: lat, lon: lon})
      this.$refs.searchOnTable.clearSelected()
      this.$root.$emit('bv::toggle::collapse', 'search-sidebar')
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.busyFlag = false;
    }
  },
  computed: {
    visible_fields() {
      return [{key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'}]
    }
  },
}
</script>