import {NavLeaf} from "@/my-leaf/nav-leaf";
import {eventBus} from "@/main";
import L from "leaflet";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {CURRENT_PRODUCT} from "@/utils/build-config";

let i1 = 0
let popup = null

let objectsPolyline = L.layerGroup();
let objectsPoints = L.featureGroup();

export class TokenLeafS57 extends NavLeaf {

    constructor() {
        super();
    }

    bbbb2() {
        if (this.multiTilesStateControl._currentState.stateName === 's57') {
            this.multiTilesStateControl._currentState.onClick(this.multiTilesStateControl)
        } else {
            this.tokensLayer.remove();
            this.tokensLayer.addTo(this.map);
        }

    }

    bbbb4() {
        if (this.multiTilesStateControl._currentState.stateName === 'tokens') {
            this.multiTilesStateControl._currentState.onClick(this.multiTilesStateControl)
        }
    }

    async init(el) {
        super.init(el, "tokenView");

        let generateRandInt = function() {
            return Math.floor( Math.random() * 200000 ) + 1;
        };

        const navURL = "https://ntiles.bgeo.fi:6027/map/{z}/{x}/{y}?{randint}";
        this.tokensLayer = L.tileLayer(navURL, {
            randint: generateRandInt,
            minZoom: 2,
            maxZoom: 20,
        })

        let osmURL = "https://api.probki.net/map/{z}/{x}/{y}";
        this.osmLayer = L.tileLayer(osmURL, {
            minZoom: 2,
            maxZoom: 20,
            opacity: 0.5
        })

        // this.map.panTo([59.45035, 24.61151])
        // this.map.zoom(14)

        this.map.setView([59.45035, 24.61151], 15);

        this.multiTilesStateControl = new L.Control.EasyButton({
            position: 'bottomleft',
            states: [{
                stateName: 's57',        // name the state
                icon: 'bi bi-toggle-on',               // and define its properties
                title: 'Toggle tokens tiles',      // like its title
                onClick: (btn) => {       // and its callback
                    this.tokensLayer.addTo(this.map);
                    this.osmLayer.addTo(this.map)
                    btn.state('tokens');    // change state on click!
                }
            }, {
                stateName: 'tokens',
                icon: 'bi bi-toggle-off',
                title: 'Toggle tokens tiles',
                onClick: (btn) => {
                    this.tokensLayer.remove();
                    this.osmLayer.remove()
                    btn.state('s57');
                }
            }]
        }).addTo(this.map)

        // console.log("yyyy1", this.multiTilesStateControl)
        // console.log("yyyy1", this.multiTilesStateControl._currentState.stateName)
        // this.multiTilesStateControl._currentState.onClick(this.multiTilesStateControl)
        // console.log("yyyy2", this.multiTilesStateControl._currentState.stateName)


        this.map.addLayer(objectsPoints);
        this.map.addLayer(objectsPolyline);

        eventBus.$off('token-selected-s57')
        eventBus.$on('token-selected-s57', wp => {
            // this.map.panTo([wp.lat, wp.lon])
            // this.map.setZoom(17)
            if (wp.id === '162150')
                this.map.setView([wp.lat, wp.lon], 15)
            else
                this.map.setView([wp.lat, wp.lon], 17)
        })

        eventBus.$off("clear-tokens-object-s57")
        eventBus.$on('clear-tokens-object-s57', () => {
            objectsPolyline.clearLayers()
            objectsPoints.clearLayers()
        })

        eventBus.$off("draw-tokens-object-s57")
        eventBus.$on("draw-tokens-object-s57", (payload) => {
            let lines = payload.lines
            let type = payload.type
            let needZoom = payload.zoom

            objectsPoints.clearLayers()
            objectsPolyline.clearLayers()

            if (type === "point") {
                let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
                this.map.addLayer(objectsPoints)
                objectCircle.addTo(objectsPoints);
            }

            if (type === 'line') {
                let object = new L.Polyline(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                object.addTo(objectsPolyline)

            } else {
                let object = new L.Polygon(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                object.addTo(objectsPolyline)
            }

            if (needZoom) {
                let allCoordinates = []
                lines.forEach(line => {
                    line.forEach(point => {
                        allCoordinates.push([point.lat, point.lon])
                    })
                })

                let p
                if (type === 'line') {
                    p = L.polyline(allCoordinates)
                } else {
                    p = L.polygon(allCoordinates)
                }

                let b = p.getBounds()
                this.map.fitBounds(b)
            }
        })

        this.map.on('contextmenu', (e) => {
            let id1 = 'map_info' + i1;

            let content = `<button id="` + id1 + `" onclick="">Map info</button>`
            popup = L.popup({autoClose: true}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            let coordinates = e.latlng

            document.getElementById(id1).addEventListener('click', (e) => {
                this.map.closePopup()
                this.showTokenInfoS57(coordinates)
            });

        })

    }

    showTokenInfoS57(coordinates) {
        let URL = 'api/v1/objectsinfo?lat=' + coordinates.lat + '&lon=' + coordinates.lng

        if (this.multiTilesStateControl._currentState.stateName === 'tokens')
            URL = 'https://ntiles.bgeo.fi:6027/objectsinfo?lat=' + coordinates.lat + '&lon=' + coordinates.lng

        store.commit("fickle/setTokensObjectInfoLoading", true)
        myAxios.get(URL).then(resp => {
            store.commit("fickle/setTokensObjectInfoLoading", false)
            eventBus.$emit('show-tokens-map-info', {info: resp.data, coordinates: coordinates})
        })
    }

}
export function bbbb() {
    alert(1)
}


