import {NavLeaf} from "@/my-leaf/nav-leaf";
import {eventBus} from "@/main";
import L from "leaflet";
import store from "@/store";
import {myAxios} from "@/services/myAxios";

let i1 = 0
let popup = null

let objectsPolyline = L.layerGroup();
let objectsPoints = L.featureGroup();

export class TokenLeafS101 extends NavLeaf {

    constructor() {
        super();
    }

    // bbbb2() {
    //     if (this.multiTilesStateControl._currentState.stateName === 's101') {
    //         this.multiTilesStateControl._currentState.onClick(this.multiTilesStateControl)
    //     }
    // }

    bbbb8() {
        this.tokensLayer.remove();
        this.tokensLayer.addTo(this.map);
    }

    async init(el) {
        super.init(el, "tokenView101");

        // let osmURL = "https://api.probki.net/map/{z}/{x}/{y}";
        // this.tokensLayer = L.tileLayer(osmURL, {
        //     minZoom: 2,
        //     maxZoom: 20,
        // })

        let generateRandInt = function() {
            return Math.floor( Math.random() * 200000 ) + 1;
        };


        const navURL = "https://ntiles.bgeo.fi:6027/s101/{z}/{x}/{y}?{randint}";
        this.tokensLayer = L.tileLayer(navURL, {
            randint: generateRandInt,
            minZoom: 2,
            maxZoom: 20,
        })

        this.tokensLayer.addTo(this.map);

        // this.map.panTo([59.45035, 24.61151])
        // this.map.zoom(14)
        this.map.setView([59.45035, 24.61151], 15);

        // this.multiTilesStateControl = new L.Control.EasyButton({
        //     position: 'bottomleft',
        //     states: [{
        //         stateName: 's101',        // name the state
        //         icon: 'bi bi-toggle-on',               // and define its properties
        //         title: 'Toggle tokens tiles',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             this.tokensLayer.addTo(this.map);
        //             btn.state('tokens');    // change state on click!
        //         }
        //     }, {
        //         stateName: 'tokens',
        //         icon: 'bi bi-toggle-off',
        //         title: 'Toggle tokens tiles',
        //         onClick: (btn) => {
        //             this.tokensLayer.remove();
        //             btn.state('s101');
        //         }
        //     }]
        // }).addTo(this.map)

        this.map.addLayer(objectsPoints);
        this.map.addLayer(objectsPolyline);

        eventBus.$off('token-selected-s101')
        eventBus.$on('token-selected-s101', wp => {
            if (wp.id === '162150')
                this.map.setView([wp.lat, wp.lon], 15)
            else
                this.map.setView([wp.lat, wp.lon], 17)

            // this.map.panTo([wp.lat, wp.lon])
            // this.map.setZoom(17)
        })

        eventBus.$off("clear-tokens-object-s101")
        eventBus.$on('clear-tokens-object-s101', () => {
            objectsPolyline.clearLayers()
            objectsPoints.clearLayers()
        })

        eventBus.$off("draw-tokens-object-s101")
        eventBus.$on("draw-tokens-object-s101", (payload) => {
            // alert(2)
            let lines = payload.lines
            let type = payload.type
            let needZoom = payload.zoom

            objectsPoints.clearLayers()
            objectsPolyline.clearLayers()

            if (type ===  "point") {
                let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
                this.map.addLayer(objectsPoints)
                objectCircle.addTo(objectsPoints);
            }

            if (type === 'line') {
                let object = new L.Polyline(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                object.addTo(objectsPolyline)

            } else {
                let object = new L.Polygon(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                object.addTo(objectsPolyline)
            }

            if (needZoom) {
                let allCoordinates = []
                lines.forEach(line => {
                    line.forEach(point => {
                        allCoordinates.push([point.lat, point.lon])
                    })
                })

                let p
                if (type === 'line') {
                    p = L.polyline(allCoordinates)
                } else {
                    p = L.polygon(allCoordinates)
                }

                let b = p.getBounds()
                this.map.fitBounds(b)
            }
        })

        this.map.on('contextmenu', (e) => {
            let id1 = 'map_info' + i1;

            let content = `<button id="` + id1 + `" onclick="">Map info</button>`
            popup = L.popup({autoClose: true}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            let coordinates = e.latlng

            document.getElementById(id1).addEventListener('click', (e) => {
                this.map.closePopup()
                showTokenInfoS101(coordinates)
            });

        })

        eventBus.$off("invalidate-s101")
        eventBus.$on('invalidate-s101', () => {
            setTimeout(() => {
                this.map.invalidateSize()
            }, 500)

        })
    }
}

export function showTokenInfoS101(coordinates) {
    // console.log("aaaaa")
    // console.log(this.multiTilesStateControl._currentState.stateName)
    // let URL = 'api/v1/objectsinfo?lat=' + coordinates.lat + '&lon=' + coordinates.lng
    //
    // alert(this.multiTilesStateControl._currentState.stateName)
    // if (this.multiTilesStateControl._currentState.stateName === 'tokens')
    let URL = 'https://ntiles.bgeo.fi:6027/objectsinfos101?lat=' + coordinates.lat + '&lon=' + coordinates.lng

    store.commit("fickle/setTokensObjectInfoLoading", true)
    myAxios.get(URL).then(resp => {
        store.commit("fickle/setTokensObjectInfoLoading", false)
        eventBus.$emit('show-tokens-map-info', {info: resp.data, coordinates: coordinates})
    })
}
