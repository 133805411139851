import {RouteBaseWPLeaf} from "@/my-leaf/routes/route-leaf-base-wp";
import L from "leaflet";
import {eventBus} from "@/main";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {prepareError, showError} from "@/my-leaf/routes/route-errors";
// import moment from "moment";
import {updateRoute} from "@/helpers/lsHelpers";

import * as dayjs from 'dayjs/dayjs.min'

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);


// let nogoList = null

export class RouteBaseRoutingLeaf extends RouteBaseWPLeaf {
    nogoList = null
    cancel_auto_route = false
    is_autorouting = false
    autorouting_points = 0
    routeRequestPoints = null
    startSegment = -1
    finishSegment = -1

    constructor() {
        super();
    }

    async initbaserouting(el) {
        super.initbasewp(el);

        this.nogoList = new L.FeatureGroup();
        this.map.addLayer(this.nogoList);

        let options = {
            position: 'topleft',
            draw: {
                polyline: false,
                circle: false,
                polygon: false,
                marker: false,
                rectangle: {shapeOptions: {color: '#f357a1', weight: 1}},
                circlemarker: false
            },
            edit: {
                featureGroup: this.nogoList,
                remove: true
            }
        };

        this.drawControl = new L.Control.Draw(options)
        // this.map.on('draw:created', function (e) {
        //     let type = e.layerType,
        //         layer = e.layer;
        //
        //     console.log("draw created")
        //     this.nogoList.addLayer(layer);
        // });

        this.map.on('draw:created', (e) => {
            let type = e.layerType,
                layer = e.layer;

            console.log("draw created")
            this.nogoList.addLayer(layer);
        });

        eventBus.$off('check-safety')
        eventBus.$on('check-safety', () => {
            // store.commit("routes/setMovingWP", store.state.routes.movingWP)
            this.checkSafety()
        })

        eventBus.$off('clear-nogo-draw')
        eventBus.$on('clear-nogo-draw', () => {
            this.nogoList.clearLayers()
        })

        eventBus.$off('save-nogo')
        eventBus.$on('save-nogo', () => {
            let resctrictedZones = [];
            this.nogoList.getLayers().forEach(area => {
                let bounds = area.getBounds()
                let restZone = {
                    "south": bounds.getSouth(),
                    "north": bounds.getNorth(),
                    "west": bounds.getWest(),
                    "east": bounds.getEast()
                }
                resctrictedZones.push(restZone)
            })
            console.log("ggg", resctrictedZones)
            eventBus.$emit('enter-zone-name', {zones: resctrictedZones, zoneslayer: this.nogoList})


        })

        eventBus.$off('show-dangers-in-wp')
        eventBus.$on('show-dangers-in-wp', (id) => {

            this.dangerMarkers.clearLayers()
            if (id !== -1) {
                store.state.routes.dangers.forEach(obj => {
                    if (obj.isDanger && (id === obj.legIndex || id === obj.legIndex - 1 || id === obj.legIndex + 1))
                        this.setDangerMarkers(obj.points, 'danger');
                })
            }
        })


        // eventBus.$off('toggle-mode-after-auto')
        // eventBus.$on('toggle-mode-after-auto', () => {
        //     alert(3)
        //     this.is_autorouting = false
        //
        //     this.startSegment = -1
        //     this.finishSegment = -1
        //
        //     this.autorouting_points = 0
        //     let autoWPMarkers = this.wpMarkers
        //     this.removeRoute()
        //     store.commit("routes/resetStorage")
        //     autoWPMarkers.forEach(wp => {
        //         this.addWP(wp.getLatLng())
        //     })
        //     this.drawLegs()
        //
        //     this.map.removeControl(this.autorouteControl)
        //     this.map.removeControl(this.upButton)
        //     this.map.removeControl(this.downButton)
        //
        //     this.autorouteControl.addTo(this.map)
        //     this.downButton.addTo(this.map)
        //     this.upButton.addTo(this.map)
        // })
    }

    aaa2(e) {

    }
    checkSafety = () => {
        let pts = []

        this.wpMarkers.forEach(wp => {
            pts.push({
                lat: wp.getLatLng().lat,
                lon: wp.getLatLng().lng,
                radius: document.getElementById('sb-inline-radius').defaultValue,
                xteLeft: document.getElementById('sb-inline-xte-l').defaultValue,
                xteRight: document.getElementById('sb-inline-xte-r').defaultValue
            })
        })

        this.startRouteCalculation(pts, [], false)
    }

    calculateRoute = (wpIndexStart = -1, wpIndexFinish = -1) => {


        this.startSegment = wpIndexStart
        this.finishSegment = wpIndexFinish

        console.log("a1")

        let resctrictedZones = [];

        store.state.fickle.routes.nogoZones.forEach(name => {
            console.log("jjj", name)
            store.state.routes.nogoZones.zones.forEach(item => {
                if (item.name === name) {
                    item.zones.forEach(zone => {
                        let restZone = {
                            "south": zone.south,
                            "north": zone.north,
                            "west": zone.west,
                            "east": zone.east
                        }
                        resctrictedZones.push(restZone)

                    })
                }
            })
        })

        // this.nogoList.getLayers().forEach(area => {
        //     let bounds = area.getBounds()
        //     let restZone = {
        //         "south": bounds.getSouth(),
        //         "north": bounds.getNorth(),
        //         "west": bounds.getWest(),
        //         "east": bounds.getEast()
        //     }
        //     resctrictedZones.push(restZone)
        // })

        let startIndex = 0
        let finishIndex = this.wpMarkers.length - 1
        let midIndex = -1

        console.log("a2")

        if (wpIndexFinish - wpIndexStart === 1) {
            startIndex = wpIndexStart
            finishIndex = wpIndexFinish
        }

        if (wpIndexFinish - wpIndexStart === 2) {
            startIndex = wpIndexStart
            finishIndex = wpIndexFinish
            midIndex = wpIndexFinish - 1
        }

        let group = new L.featureGroup([this.wpMarkers[startIndex], this.wpMarkers[finishIndex]]);
        this.map.fitBounds(group.getBounds())

        this.drawDebugLine(this.wpMarkers[startIndex], this.wpMarkers[finishIndex])

        console.log("a3")

        let pts = []
        pts.push({
            lat: this.wpMarkers[startIndex].getLatLng().lat,
            lon: this.wpMarkers[startIndex].getLatLng().lng,
            radius: document.getElementById('sb-inline-radius').defaultValue,
            xteLeft: document.getElementById('sb-inline-xte-l').defaultValue,
            xteRight: document.getElementById('sb-inline-xte-r').defaultValue
        })

        console.log("a4")
        console.log(midIndex)

        if (finishIndex === startIndex + 2 && midIndex !== -1) {
            pts.push({
                lat: this.wpMarkers[midIndex].getLatLng().lat,
                lon: this.wpMarkers[midIndex].getLatLng().lng,
                radius: document.getElementById('sb-inline-radius').defaultValue,
                xteLeft: document.getElementById('sb-inline-xte-l').defaultValue,
                xteRight: document.getElementById('sb-inline-xte-r').defaultValue
            })
        }

        console.log("a5")

        pts.push({
            lat: this.wpMarkers[finishIndex].getLatLng().lat,
            lon: this.wpMarkers[finishIndex].getLatLng().lng,
            radius: document.getElementById('sb-inline-radius').defaultValue,
            xteLeft: document.getElementById('sb-inline-xte-l').defaultValue,
            xteRight: document.getElementById('sb-inline-xte-r').defaultValue
        })

        console.log("a7")

        // alert(pts.length)

        if (pts.length === 3) {
            console.log("qqq", "next2")
            this.startRouteCalculationMiddle(pts, resctrictedZones)
        } else {
            this.startRouteCalculation(pts, resctrictedZones)
        }
    }

    async getRoute(routeId) {
        // get route progress and state
        return new Promise(resolve => {
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {
                    store.commit("routes/setProgress", resp.data.progress)

                    if (resp.data.status === 'calculating' && this.cancel_auto_route) {
                        store.commit("routes/setCalculatingStatus", false)
                        this.restoreContextAfterAuto()
                        this.cancel_auto_route = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                        // return
                    }

                    if (resp.data.status === 'done') {
                        store.commit("routes/setCalculatingStatus", false)
                        if (resp.data.isSuccess)
                            this.drawRoute(resp.data)
                        else
                            this.showError(resp.data)

                        this.restoreContextAfterAuto()
                        resolve(resp.data)
                        // alert(0)
                        // return
                    }

                    if (resp.data.status === 'calculating' && !this.cancel_auto_route) {
                        setTimeout(() => {
                            resolve(this.getRoute(routeId))
                        }, 1500);
                    }
                })
        })
    }

    startRouteCalculation(pts, zones, isAutoRoute = true) {
        this.routeRequestPoints = pts
        store.commit("routes/setRoutePointsBackup")

        this.setTileLayerWithDraught();
        const draught = document.getElementById('sb-inline-draught').value;
        const height = document.getElementById('sb-inline-height').value;
        const radius = document.getElementById('sb-inline-radius').value;
        const xte_l = document.getElementById('sb-inline-xte-l').value;
        const xte_r = document.getElementById('sb-inline-xte-r').value;
        const speed = document.getElementById('sb-inline-speed').value;
        const recommended = document.getElementById('sb-inline-use-recommended').checked;
        const ratio = document.getElementById('sb-inline-ratio').value;
        const shallowratio = document.getElementById('sb-inline-shallowratio').value;
        const shallowstrength = document.getElementById('sb-inline-shallowstrength').value;
        const avoiddistance = document.getElementById('sb-inline-avoiddistance').value;

        store.commit("routes/resetStorage")

        this.removeRoute()

        let nogoObjects = []
        store.state.fickle.routes.nogoObjects.forEach(item => {
            nogoObjects.push(item)
        })
        let nogoCategories = []
        store.state.fickle.routes.nogoCategories.forEach(item => {
            nogoCategories.push(item)
        })

        // console.log("jjj1", zones)

        let post_text = {
            useAutoRoute: isAutoRoute,
            useRecomendedTracks: recommended,
            resctrictedZones: zones,
            speedKnots: speed,
            points: pts,
            depth: draught,
            height: height,
            defaultXteLeft: xte_l,
            defaultXteRight: xte_r,
            defaultRadius: radius,
            waitSeconds: 0,
            recomendedTrackCost: ratio,
            shallowWaterCoeff: shallowratio,
            shallowWaterCost: shallowstrength,
            avoidDistance: avoiddistance,
            resctrictedIds: nogoObjects,
            resctrictedCategories: nogoCategories
        }

        navigator.clipboard.writeText(JSON.stringify(post_text))

        myAxios.get("api/v1/route/state").then(resp => {
            myAxios.post('/api/v1/route', post_text)
                .then(resp => {
                    if (resp.data.errorCode === 0 && resp.data.id) {
                        store.commit("routes/setCalculatingStatus", true)
                        // get route calculation progress and result
                        // this.getRoute(resp.data.id).then(resp => {
                        //     alert("a1" + resp)
                        // })
                        this.getRoute(resp.data.id)
                    } else {
                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                    }
                })
        }).catch(error => {
            if (error.response.status === 503) {
                alert("No free slots for route calculating, try later")
                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
                return
            } else {
                alert("ERROR CHECK: 1024")
            }

        })
    }

    async getRouteMiddle(routeId) {
        // get route progress and state
        return new Promise(resolve => {
            console.log("qqq", "next_b8")
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {
                    store.commit("routes/setProgress", resp.data.progress)

                    console.log('nnn', resp.data.status)
                    console.log("qqq", "next_b81")
                    console.log("qqq", resp.data.status)

                    if (resp.data.status === 'calculating' && this.cancel_auto_route) {
                        console.log("qqq", "next_b82")
                        console.log('nnn', resp.data.status + " cancel")
                        store.commit("routes/setCalculatingStatus", false)
                        this.restoreContextAfterAuto()
                        this.cancel_auto_route = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                        // return
                    }

                    if (resp.data.status === 'done') {
                        console.log("qqq", "next_b84")
                        console.log('nnn', resp.data.status + " done")
                        store.commit("routes/setCalculatingStatus", false)
                        // if (resp.data.isSuccess)
                        //     this.drawRoute(resp.data)
                        // else
                        //     this.showError(resp.data)
                        //
                        // this.restoreContextAfterAuto()
                        resolve(resp.data)
                        // alert(0)
                        // return
                    }

                    if (resp.data.status === 'calculating') {
                        console.log('nnn', resp.data.status + " timeout")
                        setTimeout(() => {
                            resolve(this.getRouteMiddle(routeId))
                        }, 1500);
                    }
                })
        })
    }

    async getRouteMiddle2(routeId) {
        // get route progress and state
        return new Promise(resolve => {
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {
                    store.commit("routes/setProgress", resp.data.progress)

                    console.log('nnn', resp.data.status)

                    if (resp.data.status === 'calculating' && this.cancel_auto_route) {
                        console.log('nnn', resp.data.status + " cancel")
                        store.commit("routes/setCalculatingStatus", false)
                        this.restoreContextAfterAuto()
                        this.cancel_auto_route = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                        // return
                    }

                    if (resp.data.status === 'done') {
                        console.log('nnn', resp.data.status + " done")
                        store.commit("routes/setCalculatingStatus", false)
                        if (!resp.data.isSuccess)
                            this.showError(resp.data)
                        //     this.drawRoute(resp.data)
                        // else
                        //     this.showError(resp.data)

                        // this.restoreContextAfterAuto()
                        resolve(resp.data)
                        // alert(0)
                        // return
                    }

                    if (resp.data.status === 'calculating') {
                        console.log('nnn', resp.data.status + " timeout")
                        setTimeout(() => {
                            resolve(this.getRouteMiddle2(routeId))
                        }, 1500);
                    }
                })
        })
    }

    startRouteCalculationMiddle(pts, zones, isAutoRoute = true) {

        console.log("qqq", "next_a2")

        let pts1 = [pts[0], pts[1]]
        let pts2 = [pts[1], pts[2]]

        this.routeRequestPoints = pts
        store.commit("routes/setRoutePointsBackup")

        console.log("qqq", "next_a4")

        // alert(this.startSegment + " -2- " + this.finishSegment)

        this.setTileLayerWithDraught();
        const draught = document.getElementById('sb-inline-draught').value;
        const height = document.getElementById('sb-inline-height').value;
        const radius = document.getElementById('sb-inline-radius').value;
        const xte_l = document.getElementById('sb-inline-xte-l').value;
        const xte_r = document.getElementById('sb-inline-xte-r').value;
        const speed = document.getElementById('sb-inline-speed').value;
        const recommended = document.getElementById('sb-inline-use-recommended').checked;
        const ratio = document.getElementById('sb-inline-ratio').value;
        const shallowratio = document.getElementById('sb-inline-shallowratio').value;
        const shallowstrength = document.getElementById('sb-inline-shallowstrength').value;
        const avoiddistance = document.getElementById('sb-inline-avoiddistance').value;

        console.log("qqq", "next_a6")

        store.commit("routes/resetStorage")

        let a = this.startSegment, b = this.finishSegment
        this.removeRoute()
        this.startSegment = a
        this.finishSegment = b

        console.log("qqq", "next_a8")

        // alert(this.startSegment + " -3- " + this.finishSegment)

        let post_text1 = {
            useAutoRoute: isAutoRoute,
            useRecomendedTracks: recommended,
            resctrictedZones: zones,
            speedKnots: speed,
            points: pts1,
            depth: draught,
            height: height,
            defaultXteLeft: xte_l,
            defaultXteRight: xte_r,
            defaultRadius: radius,
            waitSeconds: 0,
            recomendedTrackCost: ratio,
            shallowWaterCoeff: shallowratio,
            shallowWaterCost: shallowstrength,
            avoidDistance: avoiddistance
        }

        let post_text2 = {
            useAutoRoute: isAutoRoute,
            useRecomendedTracks: recommended,
            resctrictedZones: zones,
            speedKnots: speed,
            points: pts2,
            depth: draught,
            height: height,
            defaultXteLeft: xte_l,
            defaultXteRight: xte_r,
            defaultRadius: radius,
            waitSeconds: 0,
            recomendedTrackCost: ratio,
            shallowWaterCoeff: shallowratio,
            shallowWaterCost: shallowstrength,
            avoidDistance: avoiddistance
        }

        // navigator.clipboard.writeText(JSON.stringify(post_text))

        console.log("qqq", "next_a12")

        myAxios.get("api/v1/route/state").then(resp => {
            console.log("qqq", "next_a14")
            myAxios.post('/api/v1/route', post_text1)
                .then(resp => {
                    console.log("qqq", "next_a24")
                    if (resp.data.errorCode === 0 && resp.data.id) {
                        store.commit("routes/setCalculatingStatus", true)
                        // get route calculation progress and result
                        // this.getRoute(resp.data.id).then(resp => {
                        //     alert("a1" + resp)
                        // })
                        // alert(this.startSegment + " -4- " + this.finishSegment)

                        this.getRouteMiddle(resp.data.id).then(resp => {
                            console.log('qqq', 'next_4_4_4')

                            store.commit("routes/setRouteSegment1Points", resp)

                            console.log("qqq", "next4_1")

                            myAxios.post('/api/v1/route', post_text2)
                                .then(resp => {

                                    // alert(this.startSegment + " -5- " + this.finishSegment)

                                    if (resp.data.errorCode === 0 && resp.data.id) {
                                        console.log("qqq", "next4_2")
                                        store.commit("routes/setCalculatingStatus", true)
                                        this.getRouteMiddle2(resp.data.id).then(resp => {

                                            // alert(this.startSegment + " -6- " + this.finishSegment)

                                            console.log("qqq", "next4_4")
                                            console.log('nnn', 'next end')
                                            store.commit("routes/setRouteSegment2Points", resp)
                                            // this.drawRoute(resp.data)


                                            // alert(this.startSegment + " -7- " + this.finishSegment)

                                            let ptsall = store.state.routes.routePrevious
                                            let ptseg1 = store.state.routes.segment1Points.routePoints
                                            let ptseg2 = store.state.routes.segment2Points.routePoints
                                            this.removeRoute()
                                            this.removeRouteWithDebug()


                                            console.log("qqq", "next4")

                                            // alert(this.startSegment + " -8- " + this.finishSegment)

                                            console.log("eee", this.startSegment, this.finishSegment)
                                            console.log("eee1", a, b)

                                            ptsall.forEach((wp, i) => {
                                                if (i < a) {
                                                    this.addWP(wp, -1, false)
                                                }
                                            })

                                            ptseg1.forEach((wp, i) => {
                                                this.addWP(wp, -1, false)
                                            })

                                            ptseg2.forEach((wp, i) => {
                                                if (i > 0)
                                                    this.addWP(wp, -1, false)
                                            })

                                            ptsall.forEach((wp, i) => {
                                                if (i > b) {
                                                    this.addWP(wp, -1, false)
                                                }
                                            })


                                            this.drawLegs()
                                            this.checkSafety()
                                            this.restoreContextAfterAuto()

                                        })
                                    } else {
                                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                                    }
                                })
                        })
                    } else {
                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                    }
                })
        }).catch(error => {
            if (error.response.status === 503) {
                alert("No free slots for route calculating, try later")
                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
                return
            } else {
                alert("ERROR CHECK: 1024")
            }

        })
    }

    restoreContextAfterAuto() {
        // alert(1)
        this.is_autorouting = false
        this.autorouting_points = 0

        this.startSegment = -1
        this.finishSegment = -1

        // let autoWPMarkers = this.wpMarkers
        // this.removeRoute()
        // store.commit("routes/resetStorage")
        //
        // autoWPMarkers.forEach(wp => {
        //     this.addWP(wp.getLatLng())
        // })
        // this.drawLegs()


        console.log("aaa2", this.autorouteControl)
        // console.log("aaa", this.upButton)
        // console.log("aaa", this.downButton)

        this.map.removeControl(this.clearControl)
        this.map.removeControl(this.safetyControl)
        this.map.removeControl(this.safetyWPControl)
        this.map.removeControl(this.recalculateControl)
        this.map.removeControl(this.recalculateWPControl)
        this.map.removeControl(this.autorouteControl)
        this.map.removeControl(this.wpControl)
        this.map.removeControl(this.moveControl)
        // this.map.removeControl(this.downButton)

        this.clearControl.addTo(this.map)
        this.safetyControl.addTo(this.map)
        this.safetyWPControl.addTo(this.map)
        this.recalculateControl.addTo(this.map)
        this.recalculateWPControl.addTo(this.map)
        this.autorouteControl.addTo(this.map)
        this.wpControl.addTo(this.map)

        console.log("eee", this.movingWP)

        let movingWPId = store.state.routes.movingWP
        if (movingWPId !== -1) {
            eventBus.$emit('click-wp', movingWPId)
            this.map.panTo(this.wpMarkers[movingWPId].getLatLng())
            eventBus.$emit('show-dangers-in-wp', store.state.routes.movingWP)
        }


        // this.downButton.addTo(this.map)
        // this.upButton.addTo(this.map)
    }

    drawRoute(resp, resp2 = null) {
        // const pts = resp.routePoints.slice(1, resp.routePoints.length - 1);
        const pts = resp.routePoints

        console.log("aaa3", this.startSegment, this.finishSegment)

        store.commit("routes/setRoute", {
            route: [],
            length: '',
            dangers: [],
        })

        store.commit("routes/setRoute", {
            route: pts,
            length: resp.routeLength,
            dangers: resp.dangerObjects,
            info: resp.routePointInfos,
            debug: resp.debugInfo
        })

        console.log("eeee1", resp)

        updateRoute("route_temp_4", resp.routePointInfos)

        let weather = []
        let startdate = store.state.routes.route_settings.date
        let starttime = store.state.routes.route_settings.time

        // if (!(startdate === '' || starttime === '')) {
            pts.forEach((coordinates, i) => {
                let lat = coordinates.lat
                let lon = coordinates.lon

                let seconds = store.state.routes.info[i].fromStartSeconds
                // let datetime = moment(startdate + " " + starttime, "YYYY-MM-DD HH:mm:ss")
                let datetime = dayjs(startdate + " " + starttime).format("YYYY-MM-DD HH:mm:ss")
                // let datetimewp = moment(datetime).add(seconds, "seconds")
                let datetimewp = dayjs(datetime).add(seconds, "seconds")

                // console.log("jjj", i, seconds, datetime.calendar(), datetimewp.calendar())
                // console.log("jjj", i, lat, lon, datetimewp, datetimewp.unix())

                // alert("a3")
                // console.log("weather1", moment.unix(datetimewp.unix()).format("DD/MM/YY"))
                let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lon + '&t=' + datetimewp.unix()

                myAxios.get(URL).then(resp => {
                    console.log("weather1", resp.data)
                    weather.push(resp.data)
                }).catch(error => {
                    console.log("weather1", error)
                    weather.push({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A'})
                })

                store.commit("fickle/setWeatherWP", weather)

            })
        // }


        if (this.startSegment === 0) {
            pts.forEach((wp, i) => {
                this.addWP(wp, -1, true)
            })

            store.state.routes.routePrevious.forEach((wp, i) => {
                if (i > 1) {
                    this.addWP(store.state.routes.routePrevious[i], -1, false)
                }
            })
        }

        if (this.startSegment !== -1 && this.finishSegment === store.state.routes.routePrevious.length - 1) {
            store.state.routes.routePrevious.forEach((wp, i) => {
                if (i < this.startSegment) {
                    this.addWP(store.state.routes.routePrevious[i], -1, false)
                }
            })
            pts.forEach((wp, i) => {
                this.addWP(wp, -1, false)
            })

        }

        if (this.startSegment === -1 && this.finishSegment === -1) {
            pts.forEach((wp, i) => {
                this.addWP(wp, -1, false)
            })
        }

        // resp.dangerObjects.forEach(obj => {
        //     if (obj.isDanger)
        //         this.setDangerMarkers(obj.points, 'danger');
        //     // this.setDangerMarkers(obj.points, obj.isDanger ? require("../../assets/marker-danger.png") : require("../../assets/marker-warning.png"));
        // })

        this.drawLegs()
        this.debugLegsGroup.clearLayers()
        // let coorinates = this.map.getCenter();
        // store.commit("leaflet/setMapCenter", coorinates)
        // eventBus.$emit('find-nearest-center-wp')
    }


    showError(resp) {
        const points = ':\n\n' + this.routeRequestPoints[0].lat + ", " + this.routeRequestPoints[0].lon + ' : \n' + this.routeRequestPoints[1].lat + ", " + this.routeRequestPoints[1].lon
        prepareError(resp.errorCode, points)
    }
}
